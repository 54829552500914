import { type MetaFunction } from '@remix-run/node';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration } from '@remix-run/react';
import { useEffect, useRef } from 'react';
import styles from '~/tailwind.css';
import { useNonce } from './utils/nonce-provider.ts';

export const meta: MetaFunction = () => {
  return [{ title: 'Zamas' }, { name: 'description', content: 'Logiciel de gestion des services propreté.' }];
};

export function links() {
  return [
  { rel: 'stylesheet', href: styles },
  { rel: 'manifest', href: '/manifest.json' }];

}

export default function Root() {
  const htmlRef = useRef<HTMLHtmlElement>(null);
  const nonce = useNonce();

  useEffect(() => {
    if (htmlRef.current) htmlRef.current.classList.add('hydrated');
  }, []);

  return (
    <html lang="en" ref={htmlRef}>
			<head>
				<Links />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=0.8" />
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<meta name="apple-mobile-web-app-status-bar-style" content="default" />
				<meta name="apple-mobile-web-app-title" content="ZAMAS" />
			</head>
			<body className="thin-scrollbar font-sans">
				<Outlet />
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>);

}